import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'reactstrap';
import StepA from '../components/StepA';
import StepB from '../components/StepB';
import StepC from '../components/StepC';
import SideImage from '../components/SideImage';
import Head from '../components/Head';
import styled from 'styled-components';
import EULA from '../components/EULA';
import Loader from "react-loaders";
export default function MainForm() {

  const [isClicked, setClick] = useState(false)
  const [currStep, setCurrentStep] = useState(2)
  const [userData, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState({ display: false });
  const [agreement, setAgreement] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const domain = window.location.host.match(/signup\.(.*)$/) !== null ? window.location.host.match(/signup\.(.*)$/)[1] : window.location.host;
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const token = searchParams.toString().split('=')[1];

  useEffect(() => {
    if (token !== undefined) {
      validateMarketPlace()
    }
  }, [])


  const handleClickButton = (e) => {
    if (e.target.name === 'prev') {
      currStep === 1 ? window.location.href = 'https://' + domain + '/' : setCurrentStep(currStep - 1)
      setClick(false)
    }
    else {
      setClick(true)
      validateForm()
    }
  }

  const validateForm = () => {
    switch (currStep) {
      case (1):
        if (userData.email !== undefined && errors.email === '' && agreement === true) {
          setCurrentStep(currStep + 1)
          setClick(false)
        }
        break;
      case (2):
        const fields = ['email', 'name', 'password', 'passwordConf']
        if ((fields.find(field => (errors[field] !== '')) === undefined) && agreement === true) {
          sendSignUpRequest()
        }
        break
      default:
    }
  }

  const validateMarketPlace = async () => {

    let marketPlace = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }

    let endpoint = `https://api.${domain}`
    let apigw = localStorage.getItem('apigw')
    if (apigw !== null && apigw !== undefined) {
      endpoint = apigw
    }
    endpoint += `/azuremarketplace/subscription?token=${token}`

    try {
      const response = await fetch(endpoint, marketPlace);
      const responseData = await response.json();
      if (responseData.StatusCode=== 200) {
        if(responseData.Message === 'volumez representative will contact you soon'){
          window.location.href = `https://${domain}/app#/public-offering`
        }else{
          window.location.href = `https://${domain}/app`
        }
  
      } else if (token != null) {
        setData({ ...userData, cloudprovider: 'azure', markettoken: token })
      }
    } catch (error) {
      setModalMessage({ display: true, title: 'Error occurs', text: "connection to api gateway went wrong" })
    }
  }

  const sendSignUpRequest = async () => {
    setSpinner(true)
    const dataBody = { ...userData }
    delete dataBody.passwordConf
    let data = JSON.stringify(dataBody)
    let signupRequest = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: data
    }

    let endpoint = "https://api." + domain + "/signup"
    let apigw = localStorage.getItem('apigw')
    if (apigw !== null) {
      endpoint = `${apigw}/signup`
    }

    try {
      const response = await fetch(endpoint, signupRequest);
      const responseData = await response.json();
      if (responseData.status === 200) {
        setCurrentStep(currStep + 1)
      } else {
        setModalMessage({ display: true, title: 'Error occurs', text: responseData.message })
      }
      setSpinner(false)
    } catch (error) {
      setModalMessage({ display: true, title: 'Error occurs', text: "connection to api gateway went wrong" })
      setSpinner(false)
    }
  }

  const validateField = (e) => {
    const currErrors = errors
    setData({ ...userData, [e.target.name]: e.target.value })
    switch (e.target.name) {
      case ("email"):
        currErrors.email = "";
        if (!e.target.value.match(/^\S+@\S+\.\S+$/)) {
          currErrors.email = "The email address format is not valid"
        }
        break;
      case ("name"):
        currErrors.name = ""
        if (!e.target.value.match(/^[a-zA-Z-'.\s]{2,99}$/)) {
          currErrors.name = "Invalid Full name use only latin letters and at least 2 characters long "
        }
        break;
      case ("password"):
        const data = userData;
        currErrors.password = "";
        currErrors.passwordConf = "";
        const rePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-"!@#%&\/,><:;|_~`])\S{8,99}$/;
        if (!e.target.value.match(rePassword) && currErrors.password === "") {
          currErrors.password = "Passwords must be at least 8 characters long and contain lowercase letters, uppercase letters, numbers, and non-alphanumeric characters"
        }
        if (e.target.value !== data.passwordConf) {
          currErrors.passwordConf = "The passwords don’t match";
        }
        break;
      case ("passwordConf"):
        currErrors.passwordConf = "";

        if (userData.password !== e.target.value) {
          currErrors.passwordConf = "The passwords don’t match";
        }
        break;
      default:
    }
    setErrors(currErrors);
  }

  const signupSetPage = () => {
    switch (currStep) {
      // case 1:
      //   return <StepA step="1" validateField={validateField} errors={errors} userData={userData} isClicked={isClicked} modal={setModalMessage} setAgreement={setAgreement} agreement={agreement} />
      case 2:
        return <StepB step="2" validateField={validateField} errors={errors} userData={userData} isClicked={isClicked} modal={setModalMessage} setAgreement={setAgreement} agreement={agreement} />
      case 3:
        return <StepC step="3" validateField={validateField} userData={userData} />
      default:
    }
  }

  const buttonDisplay = () => {
    if (currStep !== 3) {
      return (
        <div className="mt-2  d-flex justify-content-end">

          {/* {currStep === 2 &&
           <Button className="mr-auto" variant="secondary" type='button' size="lg" onClick={(e) => handleClickButton(e)} name='prev'>Previous</Button>} */}
          <Button className="w-25 align-self-end" color="primary"
            size="lg"
            onClick={(e) => handleClickButton(e)}
            name='signup'>
            Sign up
          </Button>
        </div>
      )
    }
  }

  const linkDisplay = () => {
    return (
      <h6 className="mb-0 text-right">
        Already have an account?
        <a href={`https://${domain}/app`} className="text-info">&nbsp;
          Sign in
        </a>
      </h6>
    )
  }


  return (
    <MainContainer>
      <div className="h-100">
        <Row className="h-100 no-gutters">

          <Col lg="7" md="12" className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">

            {spinner
              ? <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-sync" />
                  </div>
                </div>
              </div>
              : <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <Row className="align-items-end" >
                  <Col>
                    <Head
                      title={currStep !== 3 ? "Welcome to Volumez" : `${userData.name},`}
                      subTitle={currStep !== 3 ? "" : 'Welcome to Volumez,'}
                    />
                  </Col>
                  <Col>
                    {currStep !== 3 ? linkDisplay() : ""}
                  </Col>
                </Row>
                <Row className="divider" />
                {signupSetPage()}
                <Row className="divider" />
                {buttonDisplay()}
              </Col>}
          </Col>
          <Col lg="5" className="d-xs-none">
            <SideImage></SideImage>
          </Col>
        </Row>
      </div>
      <EULA className="my-modal" title={modalMessage.title} body={modalMessage.text} show={modalMessage.display} closeModal={() => setModalMessage({ display: false })} name="showPrivacy" dialogClassName="big-modal"></EULA>
    </MainContainer>
  );
}

const MainContainer = styled.div`
overflow-x:hidden;
`
